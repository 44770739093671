import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebookF, faTwitter, faInstagram, faPinterestP} from '@fortawesome/free-brands-svg-icons'
import "../../styles/contactComponentStyles/contactus.css"

const ContactUs = () => {
  return (
    <section className="contact-section">
      <div className="container">
        <div className="contact_wrp">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-sm-12">
              <div className="contact_pg_address">
                <h3>
                  Hello there <br />
                  got a project?
                </h3>
                <div className="single_con_add">
                  <Link to="#">
                    <i className="pe-7s-home"></i>
                  </Link>
                  <p>Company Address :</p>
                  <span>
                    3567 New Alaska, <br /> Washington, United State
                  </span>
                </div>
                <div className="single_con_add">
                  <Link to="#">
                    <i className="pe-7s-mail-open-file"></i>
                  </Link>
                  <p>Email Address : </p>
                  <span>example@email .com</span>
                  <span>exmtwo@email .com</span>
                </div>
                <div className="single_con_add">
                  <Link to="#">
                    <i className="pe-7s-headphones"></i>
                  </Link>
                  <p>Contact Us :</p>
                  <span>+77-00-222-1111</span>
                  <span>+88-00-222-3333</span>
                </div>
                <div className="contact_social">
                  <Link to="#">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Link>
                  <Link to="#">
                  <FontAwesomeIcon icon={faTwitter} />
                  </Link>
                  <Link to="#">
                  <FontAwesomeIcon icon={faInstagram} />
                  </Link>
                  <Link to="#">
                  <FontAwesomeIcon icon={faPinterestP} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-8 col-sm-12 inner-contact">
              {/* <!--  Contact Form  --> */}
              <div className="contact-form">
                <h3>Send Us Message</h3>
                <form method="post" action="mailer.php" id="contact-form">
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <input
                        className="con-field"
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Name"
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <input
                        className="con-field"
                        name="email"
                        id="email2"
                        type="text"
                        placeholder="Email"
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <input
                        className="con-field"
                        name="phone"
                        id="phone"
                        type="text"
                        placeholder="Phone"
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <input
                        className="con-field"
                        name="website"
                        id="website"
                        type="text"
                        placeholder="Website"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <textarea
                        className="con-field"
                        name="message"
                        id="message2"
                        rows="6"
                        placeholder="Your Message"
                      ></textarea>
                      <div className="submit-area">
                        <input
                          type="submit"
                          className="submit-contact"
                          value="submit Now"
                        />
                        <div id="msg" className="message"></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* <!-- End:Contact Form  --> */}
            </div>

            <div className="col-sm-12 map-container">
              <iframe
                title="this is unique title"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.023938894304!2d-81.38341548467582!3d19.324767486945657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f2587a9a0b98737%3A0x38fe616bb6df631f!2s638+W+Bay+Rd%2C+Cayman+Islands!5e0!3m2!1sen!2sus!4v1549260798680"
              ></iframe>
            </div>
          </div>
          {/* <!-- row /- --> */}
        </div>
      </div>
      {/* <!-- container /- --> */}
    </section>
  )
}

export default ContactUs
