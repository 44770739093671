import React from "react"
import ContactUs from "../components/contactComponents/ContactUs"
import SingleHeader from "../components/SingleHeader"
import Layout from "../components/Layout"


export default function contact() {
  return (
    <Layout>
      <SingleHeader pageTitle="Contact Us" pageName="Contact" />
      <ContactUs />
    </Layout>
  )
}
