import React from 'react'
import { Link } from 'gatsby'
import '../styles/global/singleHeader.css'

const SingleHeader = ({pageTitle, pageName}) => {
    return (
        <header className="single-header" layout="constrained" placeholder="blurred">
        {/* <!-- Start: Header Content --> */}
            <div className="container">
                <div className="row text-center wow fadeInUp" data-wow-delay="0.5s">
                    <div className="col-sm-12">
                        {/* <!-- Headline Goes Here --> */}
                        <h3>{pageTitle}</h3>
                        <h4><Link to="/"> Home </Link> <span> | </span> {pageName} </h4>
                    </div>
                </div>
            {/* <!-- End: .row --> */}
            </div>
        {/* <!-- End: Header Content --> */}
        </header>
    )
}

export default SingleHeader
